import ObjReg from '../utils/ObjReg';
import Validator from '../utils/Validator';
import PSA from '../psa';
import FocusHolder from './FocusHolder';
import Utils from '../utils/Utils';
import LoggingBase from '../base/loggingbase';

const WAIT_SHW = 64; // default "show" wait timeout
const WAIT_OFF = 110; // default "off" wait timeout

const ANI_ACTORS = [
	'actor1',
	'actor2',
	'actor3'
];

/**
 * block screen manager
 */
export default class BscMgr extends LoggingBase {

	/**
	 * @returns {BscMgr} the block screen manager instance
	 */
	static getInstance() {
		return this.instance;
	}

	static showBlockScreen( force = false ) {
		const instance = this.instance;
		if ( !Validator.isFunctionPath( instance, "instance.shwBlkScr" ) ) {
			return false;
		}
		return instance.shwBlkScr( {
			mod: "trn",
			keepHint: true,
			sbc: true,
			force: force
		} );
	}

	static hideBlockScreen() {
		const instance = this.instance;
		if ( !Validator.isFunctionPath( instance, "instance.shwBlkScr" ) ) {
			return false;
		}
		return instance.shwBlkScr( {
			mod: "trn",
			keepHint: true,
			sbc: false
		} );
	}

	/**
	 * constructs a new instance
	 * @param {CliCbkWdg} cbw callback widget
	 */
	constructor( cbw ) {
		super('gui.BscMgr');
		BscMgr.instance = this;
		this.bscRqu = false;
		this.rquTms = 0;
		this.guiLis = null;
		this.tmoShw = WAIT_SHW;
		this.tmoOff = WAIT_OFF;
		this.cbkWdg = null;
		this.blkScr = null;
		this.aniElm = null;
		this.hintElm = null;
		this.tmsShw = 0;
		this.hdlShw = null;
		this.tmsOff = 0;
		this.hdlOff = null;
		this.srvCtr = false;
		this.hintTxt = null;
		this.tmsDsp = 0;
		this.cntLng = 0;
		this.cntSht = 0;
		this.mnuCnt = 0;
		this._srvRqu = false;
		this._focusHolder = null;
		this._suspended = false;
		this.bscLsr = new ObjReg();
		this._init( cbw );
	}

	/**
	 * called at logout to clean-up
	 */
	doDestroy() {
		this.rquTms = 0;
		this.bscRqu = false;
		this.bscLsr.clear();
		this.bscLsr.destroy();
		delete this.bscLsr;
		this._clrTimShw();
		this._clrTimOff();
		if ( this.guiLis ) {
			const lis = this.guiLis;
			this.guiLis = null;
			document.body.removeEventListener( 'mouseup', lis );
			document.body.removeEventListener( 'keydown', lis );
		}
		this.hintElm = null;
		this.aniElm = null;
		const bsc = this.blkScr;
		this.blkScr = null;
		if ( bsc ) {
			document.body.removeChild( bsc );
		}
		this.cbkWdg = null;
		super.doDestroy();
	}

	/**
	 * returns the current focus holder
	 * @returns {FocusHolder} the current focus holder
	 */
	get focusHolder() {
		return this._focusHolder;
	}

	/**
	 * sets the focus holder
	 * @param {FocusHolder} fh new focus holder; may be null
	 */
	set focusHolder(fh) {
		this._focusHolder = (fh instanceof FocusHolder) ? fh : null;
	}

	/**
	 * sets the current focus holder
	 * @param {FocusHolder} fh the current focus holder
	 */
	setFocusHolder(fh) {
		const old_fh = this.focusHolder;
		if ( (old_fh instanceof FocusHolder) && (old_fh !== fh) ) {
			this._runWithLockedFocus(fh, this, () => {
				this._callFocusHolder(false, false, true);
			});
		}
		this.focusHolder = fh instanceof FocusHolder ? fh : null;
	}

	/**
	 * removes the focus holder
	 * @param {FocusHolder} fh the focus holder to be removed
	 */
	removeFocusHolder(fh) {
		if ( (fh instanceof FocusHolder) && (this.focusHolder === fh) ) {
			this._callFocusHolder(false, false, false);
			this.focusHolder = null;
		}
	}

	/**
	 * flushes the current focus holder (if set)
	 */
	flushFocusHolder() {
		const fh = this.focusHolder;
		if ( fh instanceof FocusHolder ) {
			fh.applyChanges();
		}
	}

	/**
	 * runs a function with locked focus
	 * @param {Object} context the target object
	 * @param {Function} fnc callback the function to be ran
	 * @returns {*} the return value of the callback function
	 */
	runWithLockedFocus(context, fnc) {
		return this._runWithLockedFocus(this.focusHolder, context, fnc);
	}

	/**
	 * overrides the tiemout values
	 * @param {Number} tos "show" timeout time in milliseconds
	 * @param {Number} too "off" timeout time in milliseconds
	 */
	setBscTmo( tos, too ) {
		if ( ( typeof tos === 'number' ) && ( tos > 0 ) ) {
			this.tmoShw = tos;
		} else {
			this.tmoShw = WAIT_SHW;
		}
		if ( ( typeof too === 'number' ) && ( too > 0 ) ) {
			this.tmoOff = too;
		} else {
			this.tmoOff = WAIT_OFF
		}
	}

	/**
	 *
	 * @param {Boolean} srvctr new value of the "server control" flag
	 */
	setSrvCtr( srvctr ) {
		this._logMsg( 'Block screen server control set to "' + srvctr + '".' );
		this.srvCtr = !!srvctr;
	}

	/**
	 * called by the web server to show or hide the block screen regardless of timer shots
	 * @param {Object} args provided argument object
	 */
	shwBlkScr( args ) {
		if ( this.blkScr ) {
			let mod = args.mod;
			if ( !Validator.isString( mod ) ) {
				mod = 'trn';
			}
			this._clrTimShw();
			this._clrTimOff();
			const sbc = !!args.sbc && ( mod !== 'off' );
			const old_hint = !!args.keepHint ? this.hintTxt : null;
			const hint = sbc ? ( args.hint || old_hint ) : old_hint;
			try {
				if ( this._isDbgLog() ) {
					const rqu = sbc ? 'VIS' : 'OFF';
					this._logMsg( 'SRV - Block screen server request: ' + rqu + ' (' + mod + ')' );
				}
				this.bscRqu = sbc;
				this.hintTxt = hint;
				this.rquTms = sbc ? this._getTms() : 0;
				if ( sbc ) {
					this._show( sbc, mod, !!args.force );
				} else {
					this._iniOff( true );
				}
			} finally {
				this._srvRqu = sbc;
			}
		}
	}

	/**
	 * checks whether the block screen is currently visible
	 */
	isBscVis() {
		return this.blkScr && ( this.blkScr.style.display === 'flex' );
	}

	/**
	 * checks whether the block screen is visible due to a server request
	 */
	isSrvBlk() {
		return this._isSrvRqu() && this.isBscVis();
	}

	/**
	 * indicates whether the lock screen is currently suspended
	 * @returns {Boolean} true if the lock screen is currently suspended; false otherwise
	 */
	isSuspended() {
		return this._suspended;
	}

	/**
	 * adds a block screen listener
	 * @param {Object} lsr block screen listener to be added; it must provide the methods "getLsrKey()" and "onBscShow(Boolean)"
	 */
	addBscLsr( lsr ) {
		this.bscLsr.addObj( lsr.getLsrKey(), lsr )
	}

	/**
	 * removes a block screen listener
	 * @param {Object} lsr block screen listener to be removed
	 */
	rmvBscLsr( lsr ) {
		if ( this.bscLsr ) {
			this.bscLsr.rmvObj( lsr.getLsrKey() )
		}
	}

	/**
	 * notifies this instance that a real server notification is triggered
	 */
	setBscRqu() {
		this.bscRqu = true;
		this.rquTms = this._getTms();
		this._logMsg( "BSC REQUEST - explicit." );
	}

	/**
	 * triggers a "show" or "off" request
	 * @param {Boolean} sbc flag whether to show or hide the block screen
	 */
	show( sbc ) {
		if ( !this._suspended && !this._isSrvRqu() ) {
			if ( sbc ) {
				this._iniShw();
			} else {
				this._iniOff( false );
			}
		}
	}

	/**
	 * forces the block screen to be turned off immediately
	 */
	frcOff() {
		this._logMsg( "FRCOFF - forced OFF" );
		this._show( false, 'off' );
	}

	/**
	 * sets or clears the "suspended" state
	 * @param {Boolean} set new "suspended" state
	 */
	setSuspended(set) {
		this._suspended = !!set;
		if ( this.isSuspended() ) {
			this.frcOff();
		}
	}

	/**
	 * called if a menu is shown or hidden
	 * @param {Boolean} pop if true the a menu is shown; otherwise it is hidden
	 */
	onMnuPop( pop ) {
		if ( pop ) {
			this.mnuCnt += 1;
			this.frcOff();
		} else {
			if ( this.mnuCnt > 0 ) {
				this.mnuCnt -= 1;
			}
		}
		this._logMsg( "MNUPOP - menu pop - level = " + this.mnuCnt );
	}

	/**
	 * checks whether a popup menu is visible
	 * @returns {Boolean} true if a popup menu is visible
	 */
	hasMnuPop() {
		return ( this.mnuCnt > 0 );
	}

	/**
	 * sets the QuickFinder's input widget
	 * @param {Widget} wdg QuickFinder's input widget
	 */
	setQfnWdg( wdg ) {
		// do nothing so far - we should *not* manipulate the QF input widget bypassing RAP
	}

	/**
	 * performs the initialization
	 * @param {CliCbkWdg} cbw callback widget
	 */
	_init( cbw ) {
		this.bscRqu = false;
		this.rquTms = 0;
		this.cbkWdg = cbw;
		const bsc = document.createElement( 'div' );
		bsc.id = 'PSA.blkScr';
		bsc.className = 'blkscr';
		bsc.style.display = 'none';
		bsc.style.background = 'transparent';
		const host = document.createElement( 'div' );
		host.id = 'PSA.blkHost';
		host.className = 'blkhost';
		const ani_frm = document.createElement( 'div' );
		ani_frm.id = 'PSA.blkAni';
		ani_frm.className = 'blkani';
		const elm = [];
		for ( let i = 1; i <= 3; ++i ) {
			let c = document.createElement( 'div' );
			c.className = 'circelm circelm' + i;
			ani_frm.appendChild( c );
			elm.push( c );
		}
		const hint = document.createElement( 'div' );
		hint.className = 'blkhint';
		hint.style.display = 'none';
		host.appendChild( ani_frm );
		host.appendChild( hint );
		bsc.appendChild( host );
		document.body.appendChild( bsc );
		this.guiLis = Utils.bind( this, this._guiListener );
		document.body.addEventListener( 'mouseup', this.guiLis );
		document.body.addEventListener( 'keydown', this.guiLis );
		this.blkScr = bsc;
		this.aniElm = elm;
		this.hintElm = hint;
		this._updAniCss( false, null );
	}


	_updAniCss( ani, fnc ) {
		if ( this.aniElm && ( this.aniElm.length > 0 ) ) {
			const elms = this.aniElm;
			for ( let i = elms.length - 1; i >= 0; --i ) {
				const elm = elms[ i ];
				if ( ani ) {
					elm.classList.add( ANI_ACTORS[ i ] );
					elm.style.display = 'inherit';
					elm.style.backgroundColor = '';
					elm.style.animationPlayState = 'running';
				} else {
					elm.classList.remove( ANI_ACTORS[ i ] );
					elm.style.animationPlayState = 'paused';
					elm.style.backgroundColor = 'white';
					elm.style.display = 'none';
				}
			}
		}
	}

	/**
	 * indicates whether debug logging is active
	 */
	_isDbgLog() {
		return this.alive && this.isTraceEnabled();
	}

	/**
	 * indicates whether block screen is controlled by the web server
	 * @returns {Boolean} true if block screen is controlled by the web server; false otherwise
	 */
	_isSrvCtr() {
		return this.srvCtr;
	}

	/**
	 * indicates whether there's a request sent by the web server
	 * @returns {Boolean} true if block screen request was caused by the web server; false otherwise
	 */
	_isSrvRqu() {
		return this._srvRqu;
	}

	/**
	 * effectively shows or hides the block screen
	 * @param {Boolean} sbc "show block screen" flag
	 * @param {String} mod the mode (transparent or visible)
	 * @param {Boolean} forceShow "force" flag
	 */
	_show( sbc, mod, forceShow = false ) {
		if ( this.blkScr ) {
			this.bscRqu = false;
			this.rquTms = 0;
			const srv = this._isSrvRqu();
			const tms = srv ? 0 : this._getTms();
			let eff_sbc = !!sbc;
			const mmg = PSA.getInst().getMnuMgr();
			if ( !forceShow && mmg && mmg.hasMnu() ) {
				eff_sbc = false;
			}
			this._updAniCss( eff_sbc, null );
			this._show_elm( eff_sbc, mod, tms );
		}
	}

	/**
	 * calls the focus holder
	 * @param {Boolean} lock flag whether to lock (true) or to release (false) the focus holder, if any
	 * @param {Boolean} focus flag whether to set the focus on the focus holder, if any
	 * @param {Boolean} blur flag whether to force the focus holder to blur
	 */
	_callFocusHolder(lock, focus, blur) {
		if ( this.focusHolder instanceof FocusHolder ) {
			const fh = this.focusHolder;
			if ( lock ) {
				fh.lock();
			} else if ( fh.locked ) {
				fh.release();
			}
			if ( focus ) {
				fh.setFocus();
			} else if ( blur ) {
				fh.forceBlur(false);
			}
		}
	}

	/**
	 * runs a function with locked focus
	 * @param {FocusHolder} fh the focus holder
	 * @param {Object} context the target object
	 * @param {Function} fnc the callback function to be random
	 * @returns {*} the return value of the callback function
	 */
	_runWithLockedFocus(fh, context, fnc) {
		if ( fh instanceof FocusHolder ) {
			// run the function with locked focus holder
			fh.lock();
			try {
				return fnc.apply(context, arguments);
			} finally {
				if ( fh.locked ) {		// we must check this, because the callback function might have already released the focus holder
					fh.release();
				}
			}
		} else {
			// no focus holder available, just call the function
			return fnc.apply(context, arguments);
		}
	}

	_show_elm( sbc, mod, tms ) {
		if ( this.bscLsr ) {
			this.bscLsr.forEach( ( l ) => {
				l.onBscShow( sbc );
			} );
		}
		this._callFocusHolder(sbc, !sbc, false);
		const bsc = this.blkScr;
		bsc.style.display = sbc ? 'flex' : 'none';
		if ( sbc ) {
			const has_hint = Validator.isString( this.hintTxt );
			const bgc = has_hint ? 'rgba(255,255,255,0.75)' : 'rgba(255,255,255,0.5)';
			switch ( mod ) {
				case 'vis':
					bsc.style.backgroundColor = bgc;
					break;
				default:
					bsc.style.backgroundColor = 'transparent';
					break;
			}
			const frame = this.hintElm.parentElement;
			if ( has_hint ) {
				this.hintElm.innerHTML = this.hintTxt;
				this.hintElm.style.display = '';
				frame.classList.add( 'blkframe' );
			} else {
				this.hintElm.innerHTML = '';
				this.hintElm.style.display = 'none';
				frame.classList.remove( 'blkframe' );
			}
			this.tmsDsp = tms;
		}
		this._logMsg( "SHOW - Block Screen: " + ( sbc ? 'ON' : 'OFF' ) );
	}

	/**
	 * called by timer callback methods, changes the visibility of the block screen
	 */
	_tmoShow( sbc, mod ) {
		if ( sbc && this.hasMnuPop() ) {
			this._logMsg( "TMO_SHOW - BLOCKED - menu level = " + this.mnuCnt );
			// do *nothing*!
			return;
		}
		if ( !this._isSrvRqu() ) {
			this._show( sbc, mod );
		}
	}

	/**
	 * clears / resets the "show" timer
	 */
	_clrTimShw() {
		this._logTim( 'CLR_SHW' );
		this._clrTim( 'hdlShw' );
		this.bscRqu = false;
		this.rquTms = 0;
		this.tmsShw = 0;
	}

	/**
	 * clears / resets the "off" timer
	 */
	_clrTimOff() {
		this._logTim( 'CLR_OFF' );
		this._clrTim( 'hdlOff' );
		this.bscRqu = false;
		this.rquTms = 0;
		this.tmsOff = 0;
	}

	/**
	 * clears the specified timer
	 */
	_clrTim( tmn ) {
		let tim = this[ tmn ];
		if ( tim ) {
			this[ tmn ] = null;
			window.clearTimeout( tim );
		}
	}

	/**
	 * returns a time stamp corresponding "now"
	 */
	_getTms() {
		return Date.now();
	}

	/**
	 * initializes a "show" request
	 */
	_iniShw() {
		this._logMsg( 'INI_SHW' );
		const rqu = this.bscRqu;
		this._clrTimOff(); // a new "show" request must always stop any "stop" timer
		if ( rqu ) {
			this.bscRqu = false;
			this.rquTms = 0;
			const tms = this._getTms();
			this._logTim( 'INI_SHW - ' + tms );
			if ( !this.hdlShw ) {
				const self = this;
				this.tmsShw = tms;
				this.hdlShw = window.setTimeout( function() {
					self._doShw();
				}, this.tmoShw );
			}
		} else {
			this._logMsg( "INI_SHW - IGNORED!" );
		}
	}

	/**
	 * initializes an "off" request
	 */
	_iniOff( srv ) {
		if ( !this._isSrvCtr() ) {
			const tmo = !!srv ? 2 * this.tmoOff : this.tmoOff;
			const tms = this._getTms();
			if ( !this.hdlOff ) {
				this._logTim( 'INI_OFF - ' + tms );
				const self = this;
				this.tmsOff = tms;
				this.hdlOff = window.setTimeout( function() {
					self._doOff();
				}, tmo );
			}
		}
	}

	/**
	 * called by the "show" timer to show the block screen if still required
	 */
	_doShw() {
		this._logTim( 'DO_SHW' );
		this._clrTimShw();
		if ( !this.hdlOff ) {
			this._tmoShow( true, 'trn' );
		}
	}

	/**
	 * called by the "off" timer to hide the block screen
	 */
	_doOff() {
		this._logTim( 'DO_OFF' );
		const tms_rqu = this.rquTms;
		const bsc_rqu = this.bscRqu;
		const tms_off = this.tmsOff;
		this._clrTimOff();
		if ( this.hdlShw && ( this.tmsShw <= tms_off ) ) {
			// overtaken :-)
			this._logMsg( 'DROP - drop show request (' + this.tmsShw + ' / ' + tms_off + ')' );
			this._clrTimShw();
		}
		this._tmoShow( false, 'off' );
		if ( tms_rqu > tms_off ) {
			this._logMsg( 'RQU RESTORE - request flag: ' + ( bsc_rqu ? 'ON' : 'off' ) );
			this.bscRqu = bsc_rqu;
			this.rquTms = tms_rqu;
		}
	}

	_logMsg( msg ) {
		if ( this._isDbgLog() ) {
			const now = new Date();
			this.trace( now.toISOString() + ' - BSC: ' + msg );
		}
	}

	/**
	 * debug logging of timed events
	 */
	_logTim( rqu ) {
		if ( this._isDbgLog() ) {
			const ts = this.hdlShw ? 'SHW' : '---';
			const to = this.hdlOff ? 'OFF' : '---';
			this._logMsg( "TIM EVT: " + rqu + ' (' + ts + ' / ' + to + ')' );
		}
	}

	/**
	 * global listener for mouse and keyboard events, sets the "block screen requested" flag on a relevant event
	 */
	_guiListener( event ) {
		const tms = this._getTms();
		this.bscRqu = true;
		this.rquTms = tms;
		this._logMsg( 'REQUEST - user event. - ' + tms );
	}
}
